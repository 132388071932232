import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import { makeDataPoints } from "../../../utils";
import {
  fetchAnalytics,
  fetchAnalyticsLeadSource,
} from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import PieChart from "../../Charts/PIeChart";
import UptimeChart from "../../Charts/UptimeChart";
import { Spinner } from "../../common";
import DatePicker from "../../common/DateRangePicker";
import { EmptyIcon } from "../../icons/svg";
import AnalyticsBottomPane from "./AnalyticsBottomPane";

function EmptyContainer({ title }: any) {
  return (
    <HStack justifyContent="center" alignItems="center" h="100%">
      <EmptyIcon />
      <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
        {title}
      </Text>
    </HStack>
  );
}

const Analytics = () => {
  const dId = useUserInfo("dealership")?.id;
  const [page, setPage] = useState(1);
  const [leadSourceData, setLeadSourceData] = useState<any>([]);
  const [reportData, setReportData] = useState<any>(null);
  const [lineChartData, setLineChartData] = useState<any>(null);
  const [barChartData, setBarChartData] = useState<any>(null);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });

  const { isLoading } = useQuery({
    queryKey: [
      "fetchAnalytics",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
    ],
    queryFn: fetchAnalytics,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setReportData(data?.data?.data);
      setLineChartData({
        series: [
          {
            name: data?.data?.data?.response_time_details?.graph_data[0]?.label,
            data: makeDataPoints(
              data?.data?.data?.response_time_details?.graph_data[0]?.data
                ?.timestamps,
              data?.data?.data?.response_time_details?.graph_data[0]?.data
                ?.values
            ),
            color: "var(--primary-600)",
          },
          {
            name: data?.data?.data?.response_time_details?.graph_data[1]?.label,
            data: makeDataPoints(
              data?.data?.data?.response_time_details?.graph_data[1]?.data
                ?.timestamps,
              data?.data?.data?.response_time_details?.graph_data[1]?.data
                ?.values
            ),
            color: "#00CFC1",
          },
        ],
        isSmall: false,
      });
      setBarChartData(data?.data?.data?.messages);
    },
  });
  const { data, isLoading: isLoadingLeadSources } = useQuery({
    queryKey: [
      "fetchAnalyticsLeadSource",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      20,
    ],
    queryFn: fetchAnalyticsLeadSource,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      if (page === 1) {
        setLeadSourceData(data?.data?.data?.leads);
      } else {
        setLeadSourceData([...leadSourceData, ...data?.data?.data?.leads]);
      }
    },
  });

  return (
    <VStack>
      <HStack justifyContent="space-between" w="100%">
        <Box>
          <DatePicker
            value={selectionRange}
            onChange={(value) => {
              setSelectionRange(value);
              setLeadSourceData([]);
              setPage(1);
            }}
            w="100%"
            maxDate={new Date()}
          />
        </Box>
        <Box minH="43px">
          <ExportReport reportType="analytics" selectionRange={selectionRange}/>
        </Box>
      </HStack>

      <VStack w="100%">
        <VStack
          mt="1.5rem"
          p="1.5rem"
          borderRadius="1rem"
          border="1px solid var(--grey-300)"
          w="100%"
          alignItems="flex-start"
        >
          <Text textStyle="h3" color="var(--red-700)" fontWeight="700">
            Response Time Details
          </Text>

          <HStack
            justifyContent="space-between"
            w="100%"
            mt="1rem"
            alignItems="flex-start"
          >
            <VStack alignItems="flex-start" gap="2rem" mt="2rem">
              <VStack gap="0.5rem" alignItems="flex-start">
                <Text textStyle="h4" fontWeight="500" color="var(--grey-900)">
                  Avg New Lead Response Time
                </Text>
                <Text
                  textStyle="h2"
                  color="var(--primary-600)"
                  fontWeight={700}
                >
                  {
                    reportData?.response_time_details
                      ?.avg_new_lead_response_time
                  }
                </Text>
              </VStack>
              <VStack gap="0.5rem" alignItems="flex-start">
                <Text textStyle="h4" fontWeight="500" color="var(--grey-900)">
                  Avg Inbound Communication Response Time
                </Text>
                <Text
                  textStyle="h2"
                  color="var(--secondary-600)"
                  fontWeight={700}
                >
                  {reportData?.response_time_details?.avg_inbound_response_time}
                </Text>
              </VStack>
            </VStack>

            <VStack w="60%">
              <VStack w="100%">
                {lineChartData ? (
                  lineChartData?.series[0]?.data?.length === 0 &&
                  lineChartData?.series[1]?.data?.length === 0 ? (
                    <EmptyContainer title={"No Response Times Found."} />
                  ) : (
                    <UptimeChart data={lineChartData} />
                  )
                ) : (
                  <EmptyContainer title={"No Response Times Found."} />
                )}
              </VStack>
            </VStack>
          </HStack>
        </VStack>

        <HStack gap="1.5rem" w="100%" mt="1.5rem">
          <VStack
            borderRadius="1rem"
            border="1px solid var(--grey-300)"
            p="1.5rem 0.77375rem"
            alignItems="flex-start"
            width="calc(50% - 0.75rem)"
            h="calc(100vh - 552px)"
            // overflow="auto"
          >
            <Text textStyle="h4" fontWeight={700} color="var(--grey-900)">
              Messages
            </Text>

            <HStack justifyContent="center" w="full" h="100%">
              {barChartData ? (
                barChartData?.individual_outbound === 0 &&
                barChartData?.individual_inbound === 0 &&
                barChartData?.broadcast_outbound === 0 ? (
                  <EmptyContainer title={"No Message Found."} />
                ) : (
                  <PieChart data={barChartData} />
                )
              ) : (
                <EmptyContainer title={"No Message Found."} />
              )}
            </HStack>
          </VStack>

          <VStack
            p="1.5rem"
            borderRadius="1rem"
            border="1px solid var(--grey-300)"
            width="calc(50% - 0.75rem)"
            alignItems="flex-start"
          >
            <HStack justifyContent="space-between" w="100%">
              <Text textStyle="h4" fontWeight={700} color="var(--grey-900)">
                Lead Source
              </Text>
              <Text textStyle="h6" fontWeight={500} color="var(--grey-800)">
                Total : {data?.data?.data?.total} Leads
              </Text>
            </HStack>

            <VStack w="100%" mt="1rem">
              <div
                id="leadSourceScroll"
                style={{
                  width: "100%",
                  overflow: "auto",
                  height: "calc(100vh - 650px)",
                  paddingRight: "0.75rem",
                }}
              >
                {data?.data?.data?.total !== 0 ? (
                  <InfiniteScroll
                    dataLength={leadSourceData?.length}
                    next={() => {
                      setPage((prev) => prev + 1);
                    }}
                    hasMore={
                      data?.data?.data?.row_count > leadSourceData?.length
                    }
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="leadSourceScroll"
                    style={{ overflow: "unset" }}
                  >
                    <VStack gap="1rem">
                      {leadSourceData?.map((item: any) => (
                        <HStack
                          key={item.id}
                          justifyContent="space-between"
                          w="100%"
                        >
                          <Text
                            textStyle="h6"
                            color="var(--grey-900)"
                            fontWeight={400}
                          >
                            {item?.name}
                          </Text>
                          <Text
                            textStyle="h6"
                            color="var(--grey-900)"
                            fontWeight={500}
                          >
                            {item?.count}
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  </InfiniteScroll>
                ) : (
                  <EmptyContainer title={"No Lead Source Found."} />
                )}
              </div>
            </VStack>
          </VStack>
        </HStack>

        <VStack mt="1.5rem" w="100%">
          <AnalyticsBottomPane data={reportData} />
        </VStack>
      </VStack>
      {(isLoading || isLoadingLeadSources) && <Spinner />}
    </VStack>
  );
};

export default Analytics;
