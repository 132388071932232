import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Spinner } from "../../../components/common";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import { fetchFloorTrafficAnalysis } from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";

const FloorTrafficAnalysis = () => {
  const dId = useUserInfo("dealership")?.id;

  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });

  const colors = [
    "#FFE3E3",
    "#E3F2FD",
    "#FFFDD7",
    "#E3FFF3",
    "#EDECFF",
    "#E5F9FF",
    "#FAFAFA",
    "#ffe7fe",
  ];

  const { data, isFetching } = useQuery({
    queryKey: [
      "fetchFloorTrafficAnalysis",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
    ],
    queryFn: fetchFloorTrafficAnalysis,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: selectionRange?.startDate !== selectionRange?.endDate,
  });

  return (
    <Box>
      <HStack justifyContent="space-between" mb="1rem">
        <Box>
          <DatePicker
            value={selectionRange}
            onChange={(value) => setSelectionRange(value)}
            w="100%"
            maxDate={new Date()}
          />
        </Box>
        <ExportReport reportType="floor_traffic" selectionRange={selectionRange}/>
      </HStack>
      <HStack gap="0.25rem" flexWrap="wrap">
        {data?.data?.map((item: any, index: number) => (
          <VStack
            border="1px solid var(--grey-300)"
            justifyContent="center"
            alignItems="center"
            h="6.75rem"
            background={
              item?.name === "Credit Report Pulls"
                ? "var(--grey-200)"
                : colors[index]
            }
            color={
              item?.name === "Credit Report Pulls"
                ? "var(--grey-600)"
                : "inherit"
            }
            _hover={{
              background:
                item?.name === "Credit Report Pulls"
                  ? "var(--grey-200)"
                  : "var(--grey-200)",
              color:
                item?.name === "Credit Report Pulls"
                  ? "var(--grey-600)"
                  : "var(--primary-600)",
            }}
            w="16.3%"
          >
            <Text textStyle="h2">{item?.count}</Text>
            <Text textStyle="h6" fontStyle="500">
              {item?.name}
            </Text>
          </VStack>
        ))}
      </HStack>
      {isFetching && <Spinner />}
    </Box>
  );
};

export default FloorTrafficAnalysis;
