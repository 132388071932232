import { defineStyle } from "@chakra-ui/react";
import { variables } from "./colorVariables";

export default defineStyle({
  global: {
    ":root": {
      ...variables,
      "--icon-size": "1.125rem",
    },
    "html, body": {
      // color: "white",
      overflowY: "hidden",
      height: "100%",
      // fontFamily: "Red Hat Display",
    },
    ".roboto-font": {
      fontFamily: "var(--font-roboto)",
    },
    ".rbc-calendar": {
      background: "white",
    },
    ".rbc-calendar .rbc-time-view": {
      border: "none",
      padding: "1.5rem",
      paddingTop: "0 !important",
    },
    ".rbc-calendar .rbc-toolbar": {
      alignItems: "flex-start",
      marginBottom: "0 !important",
    },
    ".rbc-calendar .rbc-toolbar button": {
      border: "none",
      padding: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".rbc-calendar .rbc-toolbar .nav-btn": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".rbc-calendar .rbc-time-view .rbc-label.rbc-time-header-gutter": {
      padding: "0 31px",
    },

    ".rbc-calendar .rbc-time-view .rbc-time-header-cell": {
      borderBottom: "1px solid var(--bluegray-100)",
    },
    ".rbc-calendar .rbc-time-column .rbc-timeslot-group": {
      height: "4.5rem",
      flex: "none",
      borderBottom: "1px solid var(--bluegray-100)",
    },
    ".rbc-calendar .rbc-time-content": {
      borderTop: "none",
    },
    ".rbc-calendar .rbc-time-content > * + * > *": {
      borderLeft: "1px solid var(--bluegray-100)",
    },
    ".rbc-calendar .rbc-time-header-content .rbc-time-header-cell .rbc-header":
      {
        padding: "0.75rem 3px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "4rem",
        borderBottom: "none",
        _first: {
          borderLeft: "none",
        },
        borderLeft: "1px solid var(--bluegray-100)",
      },
    ".rbc-calendar .rbc-time-header-content .rbc-time-header-cell .rbc-header .rbc-button-link":
      {
        span: {
          display: "flex",
          flexDirection: "column-reverse",
          color: "var(--grey-800)",
          fontWeight: "500",
          fontSize: "0.875rem",
        },
      },
    ".rbc-calendar .rbc-time-header-content .rbc-allday-cell": {
      display: "none",
    },
    ".rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-group": {
      borderBottom: "none",
      fontSize: "0.75rem",
      fontWeight: "500",
      color: "var(--grey-600)",
    },

    ".rbc-calendar .rbc-today": {
      background: "var(--secondary-50)",
    },
    ".rbc-calendar .rbc-month-view": {
      borderRadius: "0.5rem",
      width: "calc(100% - 3rem)",
      margin: "1.5rem",
      borderColor: "var(--bluegray-100)",
      marginTop: "0 !important",
    },
    ".rbc-calendar .rbc-month-view .rbc-header": {
      borderBottom: "none !important",
      fontSize: "0.875rem",
      fontWeight: "500",
      color: "var(--grey-600)",
      paddingTop: "0.75rem",
    },
    ".rbc-calendar .rbc-month-view .rbc-off-range ": {
      // background: "transparent !important",
      // display: "none !important",
      pointerEvents: "none !important",
      visibility: 'hidden !important',

    },
    ".rbc-calendar .rbc-month-view .rbc-day-bg": {
      borderColor: "var(--bluegray-100)",
    },
    ".rbc-calendar .rbc-month-view .rbc-date-cell ": {
      textAlign: "center !important",
    },
    ".rbc-calendar .rbc-month-view .rbc-date-cell .rbc-button-link": {
      textAlign: "center !important",
      fontSize: "0.875rem",
      fontWeight: "500",
      color: "var(--grey-900)",
    },
    ".rbc-calendar .rbc-month-view .rbc-month-row .rbc-row .rbc-date-cell": {
      paddingTop: "0.75rem",
    },
    ".rbc-calendar .rbc-month-view:last-child .rbc-month-row .rbc-row .rbc-date-cell":
      {
        paddingTop: "0",
      },
    ".rbc-row-segment .rbc-event": {
      backgroundColor: "unset",
      padding: "0 0.75rem",
    },
    // ".rbc-row-content": {
    //   overflow: "auto",
    //   padding: "0 !important",
    // },
    // ".rbc-row-content .rbc-row ": {
    //   position: "sticky",
    //   top: 0,
    //   left: 0,
    //   width: "100%",
    //   background: "white",
    // },
    ".rbc-day-slot .rbc-event": {
      // width: "100% !important",
      // left: "0 !important",
      // border: "none !important",
      backgroundColor: "unset !important",
      borderColor: "var(--grey-300)",
      borderRadius: "0.5rem",
      padding: "0 !important",
      // minHeight: "100%",
    },
    ".rbc-day-slot .rbc-event .rbc-event-label": {
      display: "none !important",
    },
    ".rbc-day-slot .rbc-events-container": {
      marginRight: "0 !important",
    },
    ".react-calendar__tile": {
      width: "2.25rem",
      height: "2.25rem",
      color: "var(--grey-900)",
      fontSize: "0.75rem",
      fontWeight: "400",
    },
    ".react-calendar__month-view__weekdays__weekday": {
      width: "2.25rem",
      height: "2.25rem",
      textAlign: "center",
    },
    ".react-calendar__month-view__weekdays__weekday abbr": {
      color: "var(--grey-900)",
      fontSize: "0.75rem",
      fontWeight: "500",
      textAlign: "center",
      textDecoration: "none",
    },
    ".react-calendar__tile.react-calendar__tile--active": {
      background: "var(--primary-600)",
      color: "white",
      borderRadius: "50%",
    },
    ".react-calendar__navigation__label": {
      color: "var(--grey-700)",
      fontSize: "1rem",
      fontWeight: "600",
    },
    ".react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":
      {
        display: "none",
      },
    ".react-calendar__navigation": {
      position: "relative",
      padding: "1rem 0.75rem 0.75rem",
    },
    ".react-calendar__navigation__prev-button": {
      position: "absolute",
      right: "2rem",
      fontSize: "1.2rem",
      top: "50%",
      transform: "translateY(-50%)",
    },
    ".react-calendar__navigation__next-button": {
      position: "absolute",
      right: "0.75rem",
      fontSize: "1.2rem",
      top: "50%",
      transform: "translateY(-50%)",
    },
    "#chakra-modal-phone-drawer": {
      width: "22rem",
      left: "unset !important",
      right: "1.5rem !important",
    },
    "#chakra-modal-chat-drawer": {
      width: "24rem",
      left: "unset !important",
      right: "5% !important",
    },
    "#chakra-modal-email-drawer": {
      width: "41rem",
      left: "unset !important",
      right: "1.5rem !important",
    },
    "#chakra-modal-variable-drawer": {
      width: "32rem",
      left: "unset !important",
      right: "40% !important",
    },
    "#chakra-modal-variable-drawer-right": {
      width: "30rem",
      left: "unset !important",
      right: "15px !important",
    },
    ".pie-chart-wrapper .apexcharts-legend ": {},
    ".pie-chart-wrapper .apexcharts-legend .apexcharts-legend-series": {
      width: "46%",
    },
    ".dateInput": {
      padding: "10px",
      width: "275px",
      borderRadius: "8px",
      backgroundColor: "#fff",
      border: "1px solid #E0E0E0",
      cursor: "pointer",
      position: "relative",
    },

    ".dateRangePicker .rdrDateDisplayWrapper": {
      display: "none",
    },
    ".rdrDateRangePickerWrapper": {
      borderRadius: "16px",
      boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",

      fontFamily: "var(--font-red-hat)",
      /* padding-bottom: 64px; */
      marginTop: "0 !important",
      borderBottom: "1px solid #E0E0E0 !important",
    },
    ".rdrInputRanges": {
      display: "none",
    },
    ".rdrNextPrevButton": {
      borderRadius: "8px !important",
      background: "#E3F2FD !important",
      width: "30px !important",
      height: "30px !important",
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
    ".rdrNextPrevButton i": {
      transform: "unset !important",
    },
    ".rdrDefinedRangesWrapper": {
      borderRight: "solid 1px #E0E0E0 !important",

      padding: "10px",
      width: "120px !important",
      borderTopLeftRadius: "0.5rem",
      borderBottomLeftRadius: "0.5rem",
    },
    ".rdrStaticRange": {
      borderBottom: "unset !important",
      marginTop: "21px !important",
    },
    ".rdrStaticRangeLabel": {
      padding: "0 10px !important",
    },
    ".rdrMonthAndYearPickers": {
      display: "none !important",
    },
    ".rdrMonthName": {
      marginTop: "-42px",
      display: "flex",
      justifyContent: "center",
      color: "#212121",
      textAlign: "center",
      // fontFamily: "Red Hat Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "20px",
    },
    ".rdrMonth": {
      width: "246px !important",
    },
    ".rdrDays": {
      marginTop: "10px !important",
    },
    ".rdrStartEdge": {
      borderRadius: "8px 0px 0px 8px !important",
      background: "#1565C0 !important",
      top: "0 !important",
    },
    ".rdrInRange": {
      background: "#E3F2FD !important",
      top: "0 !important",
    },
    ".rdrStartEdge .rdrDayNumber": {
      color: "#fff !important",
      height: "100% !important",
      top: "0 !important",
    },
    ".rdrEndEdge .rdrDayNumber": {
      color: "#fff !important",
    },

    ".rdrInRange .rdrDayNumber span": {
      color: "#1565C0 !important",
    },
    ".rdrEndEdge": {
      borderRadius: "0px 8px 8px 0px !important",
      background: "#1565C0 !important",
      top: "0 !important",
    },
    ".rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span": {
      color: "#fff !important",
    },
    ".rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span": {
      color: "#fff !important",
    },
    ".rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span":
      {
        color: "#1565C0 !important",
      },
    ".rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge":
      {
        borderTopLeftRadius: "unset !important",
        borderBottomLeftRadius: "unset !important",
      },
    ".rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge":
      {
        borderTopRightRadius: "unset !important",
        borderBottomRightRadius: "unset !important",
      },
    ".rdrDay": {
      height: "36px !important",
      width: "32px !important",
    },
    ".rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge": {
      height: "100% !important",
    },
    ".rdrDayNumber": {
      height: "100% !important",
      top: "0 !important",
    },
    ".rdrMonthAndYearWrapper": {
      position: "relative",
      zIndex: 1,
    },
    ".rdrDateDisplayWrapper": {
      display: "none",
    },
    ".rdrCalendarWrapper.rdrDateRangeWrapper": {
      borderTopRightRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
    },
    ".rdrCalendarWrapper .rdrMonthsVertical .rdrDays .rdrDay .rdrSelected": {
      height: "calc(100% - 4px) !important",
      top: "2px !important",
      width: "100%",
      left: 0,
      borderRadius: "50%",
    },
    ".rdrCalendarWrapper .rdrMonthsVertical .rdrDays .rdrDay .rdrSelected ~ .rdrDayNumber span":
      {
        color: "white !important",
      },
    ".rdrCalendarWrapper .rdrMonthsVertical .rdrDays .rdrDay.rdrDayHovered": {
      border: "none",
      // borderRadius: "50%",
    },
    ".rdrCalendarWrapper .rdrMonthsVertical .rdrDays .rdrDay.rdrDayHovered .rdrDayNumber::after":
      {
        all: "unset",
      },
    ".mySwiper": {
      display: "flex",
    },
    ".mySwiper .swiper-slide": {
      width: "7.75rem !important",
    },
    ".mySwiper img": {
      width: "100%",
      height: "6.4rem",
      objectFit: "cover",
    },
    ".mySwiper2, .mySwiper": {
      width: "100%",
    },
    ".mySwiper2.showNavs .swiper-button-prev": {
      display: "block",
    },
    ".mySwiper2.showNavs .swiper-button-next": {
      display: "block",
    },
    ".mySwiper2.showNavs .swiper-button-next:after, .mySwiper2.showNavs .swiper-button-prev:after":
      {
        fontSize: "2.5rem",
      },
    ".mySwiper2 .swiper-slide": {
      objectFit: "cover",
      height: "22rem",
    },
    ".mySwiper2 .swiper-button-prev": {
      display: "none",
    },
    ".mySwiper2 .swiper-button-next": {
      display: "none",
    },
    ".mySwiper2 .swiper-slide img": {
      width: "100%",
      objectFit: "contain",
      height: "100%",
    },
    ".vehicle-responsive": {
      ".header": {
        "@media(max-width: 900px)": {
          padding: "1rem 2rem",
        },
        "@media(max-width: 768px)": {
          padding: "1rem",
        },
      },
      ".car-details-wrapper": {
        "@media(max-width: 768px)": {
          padding: "1rem",
        },
        "@media(max-width: 900px)": {
          flexWrap: "wrap",
          ".slider-section, .detail-section": {
            width: "100%",
          },
          ".slider-section": {
            padding: 0,
          },
        },
      },
    },
  },
});
