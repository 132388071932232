import { Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { ContactCardIcon } from "../../../../../icons/svg";
import Activity from "../Activity/Activity";

type SingleContactCardProps = {
  item: any;
};

function SingleContactCard({ item }: SingleContactCardProps) {

  const showDncUnDncDescription = () => {
    if(item?.details?.dnc) {
      if(item?.details?.by === "GoCRM Admin") {
        return "DNC By: System";
      } else {
        return `DNC By: ${item?.details?.by}`;
      }
    } else {
      if(item?.details?.by === "GoCRM Admin") {
        return "Opt In via Keywords";
      } else {
        return `Un-DNC - ${item?.details?.by}`;
      }
    }
  }

  return (
    <Activity
      key={item.id}
      id={item.id}
      //   handleClick={() => setClickedRecord(item)}
      title={`${
        item.details?.hasOwnProperty("block")
          ? item?.details?.block
            ? "Contact Blocked"
            : "Contact Unblocked"
          : ""
      } ${
        item.details?.hasOwnProperty("dnc")
          ? item?.details?.dnc
            ? "Contact DNC"
            : item?.details?.by == "GoCRM Admin"
            ? "Contact - Opt In"
            : "Contact Un-DNC"
          : ""
      } ${
        item.details?.hasOwnProperty("enabled")
          ? !item?.details?.enabled
            ? "Sarah Paused"
            : "Sarah Unpaused"
          : ""
      }`}
      dateAndTime={moment(item.created_at).format("dddd MMM DD YY • hh:mm a")}
      icon={<ContactCardIcon />}
      content={
        item?.sub_type === "merged" ? (
          <VStack alignItems="flex-start" gap="0.25rem">
            <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
              Merged Name: {item?.details?.merged_contact_name}
            </Text>
            <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
              Merged By: {item?.details?.by=='GoCRM Admin'?"System":item?.details?.by}
            </Text>
          </VStack>
        ) : 
        item?.sub_type === "sarah_updated" ? (
          <VStack alignItems="flex-start" gap="0.25rem">
            <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
              {!item?.details?.enabled ? 
                "Sarah Paused By" : 
                "Sarah Unpaused By"} : 
              {
               item?.details?.updated_by_sarah ? 
                "Sarah.ai" :
               item?.details?.by=='GoCRM Admin' ?
               "System" :
               item?.details?.by
              }
            </Text>
          </VStack>
        ) :
        (
          <VStack alignItems="flex-start" gap="0.25rem">
            {item.details?.hasOwnProperty("block") ||
            item.details?.hasOwnProperty("dnc") ? (
              <>
                {item.details?.hasOwnProperty("block") ? (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    {item?.details?.block ? "Blocked": "Unblocked"} By: {item?.details?.by=='GoCRM Admin'?"System":item?.details?.by}
                  </Text>
                ) : (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    {showDncUnDncDescription()}
                  </Text>
                )}
              </>
            ) : (
              <>
                { item?.details?.first_name && (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    First Name: {item?.details?.first_name}
                  </Text>
                )}
                { item?.details?.last_name && (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    Last Name: {item?.details?.last_name}
                  </Text>
                )}
                { item?.details?.phone_number && (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    Phone Number: {item?.details?.phone_number}
                  </Text>
                )}
              </>
            )}
          </VStack>
        )
      }
    />
  );
}

export default SingleContactCard;
