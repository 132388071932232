import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import SellIcon from "@mui/icons-material/Sell";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginatedTable } from "../../../components/common";
import { WhiteMsg, WhitePhone } from "../../../components/icons/svg";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import {
  fetchSoldReportListing,
  fetchSoldReportTitle,
} from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";
import DatePicker from "../../common/DateRangePicker";

const SoldReport = () => {
  const dealership = useUserInfo("dealership");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchSoldReportListing",
      dealership?.id,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
    ],
    queryFn: fetchSoldReportListing,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: title, isLoading: isLoadingReport } = useQuery({
    queryKey: [
      "fetchSoldReportTitle",
      dealership?.id,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
    ],
    queryFn: fetchSoldReportTitle,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const TableHeader = [
    { id: 1, value: "Lead Source", label: "name" }, //Lead Providers are hyperlinked and it'll take us to Lead Center by applying the Lead Source filter
    { id: 2, value: "Sold", label: "sold" }, // It'll be hyperlinked and it'll take us to Lead Center by applying 2 filters: Lead Source and ""Sold"" Disposition
    { id: 3, value: "Front Gross", label: "frontGross" },
    { id: 4, value: "Back Gross", label: "backGross" },
    { id: 5, value: "Total Gross", label: "totalGross" },
    { id: 6, value: "Avg Lead Age", label: "avgLeadAge" },
    { id: 8, value: "Avg Front", label: "avgFront" },
    { id: 9, value: "Avg Back", label: "avgBack" },
    { id: 10, value: "Avg Gross", label: "avgGross" },
    { id: 11, value: "OUTB SMS", label: "outbSMS" },
    { id: 12, value: "OUTB Calls", label: "outbCalls" },
    { id: 13, value: "Avg SMS/Sold", label: "avgSMSSold" },
    { id: 14, value: "Avg Calls/Sold", label: "avgCallsSold" },
  ];

  const handleLeadSourceOnClick = (id: string, name: string) => {
    const leadSource = {
      value: id,
      label: name,
    };
    const filter = {
      ...defaultFilterValues,
      filter_and_sort: {
        ...defaultFilterValues.filter_and_sort,
        contact_attributes: {
          ...defaultFilterValues.filter_and_sort.contact_attributes,
          lead_source: [leadSource],
        },
      },
    };
    handleSubmit(filter.filter_and_sort);
  };

  const handleSoldOnClick = () => {
    // const tag = { value: clickedRow?.id, label: clickedRow?.name };
    // const filter = {
    //   ...defaultFilterValues,
    //   filter_and_sort: {
    //     ...defaultFilterValues.filter_and_sort,
    //     contact_attributes: {
    //       ...defaultFilterValues.filter_and_sort.contact_attributes,
    //       disposition_status: [tag],
    //     },
    //   },
    // };
    // handleSubmit(filter.filter_and_sort);
  };

  const handleSubmit = (filter: any) => {
    localStorage.setItem("search_and_filter", JSON.stringify(filter));
    const url = `/leads/lead-details`;
    navigate(url);
  };

  const TableData = data?.data?.data?.map((item: any) => ({
    name: (
      <Text
        onClick={() => handleLeadSourceOnClick(item.lead_source_id, item.name)}
        whiteSpace="nowrap"
        color="var(--primary-600)"
      >
        {item.name}
      </Text>
    ),
    sold: (
      <Text onClick={handleSoldOnClick} color="var(--primary-600)">
        {item.sold}
      </Text>
    ),
    avgLeadAge: item.avg_lead_age,
    frontGross: item.front_gross,
    backGross: item.back_gross,
    totalGross: item.total_gross,
    avgAge: item.avg_lead_age,
    avgFront: item.avg_front,
    avgBack: item.avg_back,
    avgGross: item.avg_gross,
    outbSMS: item.outbound_sms,
    outbCalls: item.outbound_calls,
    avgSMSSold: item.avg_sms_per_sold,
    avgCallsSold: item.avg_calls_per_sold,
  }));

  return (
    <>
      <Box>
        <HStack justifyContent="space-between" mb="1rem">
          <Box>
            <DatePicker
              value={selectionRange}
              onChange={(value) => {setSelectionRange(value); setPage(1);}}
              w="100%"
              maxDate={new Date()}
            />
          </Box>
          <ExportReport reportType="sold_report" selectionRange={selectionRange}/>
        </HStack>
        <HStack
          paddingBottom="1rem"
          mb="1rem"
          borderBottom="1px solid var(--grey-300)"
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start" gap="0">
            <Text textStyle="h3" fontWeight="700">
              SimpSocial ROI Report
            </Text>
            <Text textStyle="h3" fontWeight="500">
              {dealership?.name}
            </Text>
            <Text
              textStyle="h4"
              color="var(--grey-700)"
              fontWeight="500"
              mt="0.5rem"
            >
              From{" "}
              {moment(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ).format("MMM DD YYYY")}{" "}
              - {moment().format("MMM DD YYYY")}
            </Text>
          </VStack>
          <HStack gap="0.61rem">
            <VStack
              padding="1rem"
              borderRadius="0.7rem"
              background="var(--primary-900)"
              minWidth="150px"
              height="120px"
            >
              <SellIcon sx={{ color: "#fff", height: "28px" }} />
              <Text textStyle="h4" fontWeight="700" color="white">
                {title?.data?.data?.total_sold}
              </Text>
              <Text textStyle="captionSmall" fontWeight="700" color="white">
                Total Sold
              </Text>
            </VStack>
            <VStack
              padding="1rem"
              borderRadius="0.7rem"
              background="var(--primary-900)"
              minWidth="150px"
              height="120px"
            >
              <WhiteMsg />
              <Text textStyle="h4" fontWeight="700" color="white">
                {title?.data?.data?.outbound_sms_on_sold}
              </Text>
              <Text
                textStyle="captionSmall"
                fontWeight="700"
                color="white"
                textAlign="center"
              >
                Outbound SMS on Sold
              </Text>
            </VStack>
            <VStack
              padding="1rem"
              borderRadius="0.7rem"
              background="var(--primary-800)"
              minWidth="150px"
              height="120px"
            >
              <WhitePhone />
              <Text textStyle="h4" fontWeight="700" color="white">
                {title?.data?.data?.outbound_calls_on_sold}
              </Text>
              <Text
                textStyle="captionSmall"
                fontWeight="700"
                color="white"
                textAlign="center"
              >
                Outbound Calls on Sold
              </Text>
            </VStack>
            <VStack
              padding="1rem"
              borderRadius="0.7rem"
              background="var(--primary-700)"
              minWidth="150px"
              height="120px"
            >
              <WhiteMsg />
              <Text textStyle="h4" fontWeight="700" color="white">
                {title?.data?.data?.avg_sms_per_sold}
              </Text>
              <Text textStyle="captionSmall" fontWeight="700" color="white">
                Avg to SMS/Sold
              </Text>
            </VStack>
            <VStack
              padding="1rem"
              borderRadius="0.7rem"
              background="var(--primary-600)"
              minWidth="150px"
              height="120px"
            >
              <WhitePhone />
              <Text textStyle="h4" fontWeight="700" color="white">
                {title?.data?.data?.avg_calls_per_sold}
              </Text>
              <Text textStyle="captionSmall" fontWeight="700" color="white">
                Avg Calls/Sold
              </Text>
            </VStack>
          </HStack>
        </HStack>
        <Text textStyle="caption" color="var(--grey-600)" mb="1rem">
          Found {data?.data?.total_count}
        </Text>
        <PaginatedTable
          header={TableHeader}
          data={TableData}
          maxPageNumbers={5}
          hasPadding={false}
          lastCenter={false}
          isSmall={true}
          itemsPerPage={perPage}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
            setPage(1);
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={data?.data?.total_count}
          isLoadingData={isLoading || isLoadingReport}
        />
      </Box>
    </>
  );
};

export default SoldReport;
