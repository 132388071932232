import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchContacts = async (dId: any, params: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts`,
    {
      params,
    }
  );
};
export const fetchContactsById = async (dId: any, id: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/${id}`
  );
};
export const fetchPhoneNumberList = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}`
  );

export const fetchDealershipEmail = async (dId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/user_email`
  );
};

export const fetchPrefix = async (dId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/prefixes`
  );
};
export const fetchResidencyStatus = async (dId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/residency_status`
  );
};
export const updateContact = async (payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}`,
    payload.payload
  );
};

export const updateContactDisposition = async (payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/assign_dispositions`,
    payload.payload
  );
};
export const updateDNC = async (params: any, payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dId}/contacts/${params.id}/dnc`,
    payload
  );
};
export const updateBlock = async (params: any, payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dId}/contacts/${params.id}/block`,
    payload
  );
};
export const updateSarah = async (params: any, payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dId}/contacts/${params.id}/change_contact_sarah_settings`,
    payload
  );
};
export const pushToCrm = async (params: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dId}/contacts/${params.id}/push_to_crm_details`
  );
};
export const checkPushToCrmPermission = async (dId: any, contactId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/${contactId}/push_to_crm_permission`,
  );
};
export const saveAsTag = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/assign_tag`,
    payload.payload
  );
};

//..................assign/unassign tags............
export const fetchTagsForContact = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/contact_tags`
  );

export const assignTag = async (params: any) =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dId}/contacts/${params.id}/assign_contact_tag?tag_id=${params.tagId}&&days=${params.days}`
  );
export const unAssignTag = async (params: any) =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dId}/contacts/${params.id}/remove_contact_tag?tag_id=${params.tagId}`
  );
