import { Grid, GridItem, HStack, Text, Button, VStack } from "@chakra-ui/react";
import { useState } from "react";
import SwitchToggle from "../common/Switch";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getPermissions,
  updatePermissions,
  updateDealershipPermissions,
} from "../../utils/api/usersetting.api";
import Loader from "../common/Spinner";
import { useToast } from "@chakra-ui/react";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { useParams } from "react-router-dom";

export const ManageNotificationPermissions = (props: any) => {
  const { id } = useParams();
  const userID = useUserInfo("id");
  const dealership = useUserInfo("dealership");
  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["notification_permissionsapi", id ?? userID, dealership?.id, true],
    queryFn: props?.api ? props?.api : getPermissions,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: any) {
      setPermissions(props?.api ? data?.data?.notification_permissions : data?.data?.permissions);
    },
  });
  const mutation = useMutation(
    props?.patchApi ? updateDealershipPermissions : updatePermissions
  );
  const toast = useToast();
  const [permissions, setPermissions] = useState<any>();
  const handleSubmit = () => {
    mutation.mutate(
      {
        payload: { notification_permissions: permissions },
        id: id ?? userID,
      },
      {
        onSuccess: () => {
          toast({
            description: "Permissions updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error, variables, context) => {
          toast({
            description: "Something went wrong while updating permissions.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleToggle = (val: boolean, moduleKey: string) => {
    // Update permissions array based on moduleKey and val
    const updatedPermissions = permissions.map((item: any) => {
      if (item.module_key === moduleKey) {
        // Update the allowed property for the specific module
        return { ...item, allowed: val };
      }
      return item;
    });

    // Update the state with the new permissions array
    setPermissions(updatedPermissions);
  };

  return (
    <VStack w="100%" position="relative">
      <HStack
        gap="0.75rem"
        w="100%"
        h="calc(100vh - 275px)"
        overflow="auto"
        pb="5rem"
        alignItems="flex-start"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
      >
        <Grid
          templateColumns="repeat(2, 1fr)"
          background="var(--grey-50)"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
          gap="1.5rem"
          alignItems="flex-start"
          w="100%"
          pointerEvents={props.allowed ? "auto" : "none"}
        >
          {permissions?.map((item: any) => (
            <GridItem>
              <SwitchToggle
                label={item.display_name}
                id={"1"}
                defaultChecked={item?.allowed}
                onChange={(val) => handleToggle(val, item.module_key)}
              />
            </GridItem>
          ))}
        </Grid>
      </HStack>
      {(isLoading || mutation.isLoading) && <Loader />}
      <HStack
        position="absolute"
        bottom="0"
        w="100%"
        right="0"
        background="white"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <Button
          width="8rem"
          onClick={handleSubmit}
          opacity={props.allowed ? 1 : 0.5}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
}
