import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import incommingCall from "../../../../assets/images/incomingCall.svg";
import outgoingCall from "../../../../assets/images/outgoingCall.svg";
import { Spinner } from "../../../../components/common";
import {
  BlockUserIcon,
  BroadcastIcon,
  ChatIcon,
  DNCIcon,
  EmptyIcon,
  InventoryMappingIcon,
  LastActiveSaleIcon,
  LastActivityIcon,
  MeetingCalendarIcon,
  PointPersonIcon,
  SarahAIIcon,
  SettingsIcon,
  TagsIcon,
} from "../../../../components/icons/svg";
import {
  convertDateContacts,
  dealershipRoleChecker,
  getLastActivity,
} from "../../../../utils";
import {
  checkPushToCrmPermission,
  fetchContactsById,
  fetchDealershipEmail,
  pushToCrm,
  updateBlock,
  updateContactDisposition,
  updateDNC,
  updateSarah,
} from "../../../../utils/api/contact-details";
import { disableOpenPhone } from "../../../../utils/api/dealership-setting.api";
import { getHistory } from "../../../../utils/api/history.api";
import { fetchUserById } from "../../../../utils/api/users.api";
import { useBreadcrumb } from "../../../../utils/context/BreadCrumsContext/BreadcrumbContext";
import {
  useHistoryDispatch,
  useHistoryState,
} from "../../../../utils/context/HistoryContext";
import { useTwilioState } from "../../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import Popup from "../../../Charts/Popup";
import DebouncedAsyncSelect from "../../../common/DebouncedAsyncSelect";
import EmailDrawer from "../../../Drawers/EmailDrawer";
import { InsertVariableDrawer } from "../../../Drawers/InsertVariableDrawer";
import OutboundCall from "../../../Drawers/OutboundCall";
import { loadDispositionSettings } from "../../../Drawers/SearchAndFilterDrawer/utils";
import SmsDrawer from "../../../Drawers/SmsDrawer";
import { CreateAppointment } from "../../../MeetingCalendar/CreateAppointment";
import { AssignUnassignTags } from "./ContactSettings/AssignUnassignTags";
import ContactSettings from "./ContactSettings/ContactSettings";
import { EnrolledCampaignDrawer } from "./ContactSettings/EnrolledCampaignDrawer";
import InboundCall from "./InboundCall";

const ContactDetails = (props: any) => {
  const { id, refetch: parentRefetch } = props;
  const user = useUserInfo("user");
  const dealer = user?.dealership;
  const role = useUserInfo("user")?.role;
  let impSSRole = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
    : "";

  const toast = useToast();
  const navigate = useNavigate();
  const [isCPDrawerOpen, setIsCPDrawerOpen] = useState<boolean>(false);
  const [isEmailCenterOpen, setIsEmailCenterOpen] = useState<boolean>(false);
  const [isSmsCenterOpen, setIsSmsCenterOpen] = useState<boolean>(false);
  const [isExpandChat, setIsExpandChat] = useState<boolean>(true);
  const [isVariableOpen, setIsVariableOpen] = useState<boolean>(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState<boolean>(false);
  const [isOutBoundCall, setIsOutBoundCall] = useState<boolean>(false);
  const [isInBoundCall, setIsInBoundCall] = useState<boolean>(false);
  const [isAppointmentOpen, setIsAppointmentOpen] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenTags,
    onClose: onCloseTags,
    onOpen: onOpenTags,
  } = useDisclosure();
  const { openSMS, openAppointment } = useTwilioState();

  const [isOpenDNCAlert, setIsOpenDNCAlert] = useState(false);
  const [isOpenBlockAlert, setIsOpenBlockAlert] = useState(false);
  const [isOpenPushToCrm, setIsOpenPushToCrm] = useState(false);
  const [isOpenSarahAlert, setIsOpenSarahAlert] = useState(false);
  const onCloseDNCAlert = () => setIsOpenDNCAlert(false);
  const onCloseBlockAlert = () => setIsOpenBlockAlert(false);
  const onClosePushToCrm = () => setIsOpenPushToCrm(false);
  const onCloseSarahAlert = () => setIsOpenSarahAlert(false);

  const [clickedRow, setClickedRow] = useState<any>();
  const [dispositions, setDispositions] = useState<any>();

  const dispatch = useHistoryDispatch();
  const { shouldRefetchDetail } = useHistoryState();
  const { devices } = useTwilioState();

  const { disableOnErrorRes, setDisableOnErrorRes } = useBreadcrumb();

  const location = useLocation();
  let [searchParams] = useSearchParams();
  const contact_id = location?.state?.contact_id;
  const contactId = searchParams.get("contact_id") ?? contact_id;
  const [micPermission, setMicPermission] = useState<
    "granted" | "denied" | "prompt" | null
  >(null);
  console.log(micPermission);
  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });
        setMicPermission(permissionStatus.state);

        // Listen for permission changes
        permissionStatus.onchange = () => {
          setMicPermission(permissionStatus.state);
        };
      } catch (error) {
        console.error("Error checking microphone permission:", error);
        setMicPermission(null);
      }
    };

    checkMicrophonePermission();
  }, []);

  const {
    isLoading: isLoadingContact,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["fetchContactById", id],
    queryFn: () =>
      fetchContactsById(
        dealer?.id,
        contactId && contactId !== "undefined" ? contactId : id
      ),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== undefined,
    onSuccess(data) {
      if (clickedRow?.id !== data?.data?.id) {
        props.setClickedRecord && props.setClickedRecord(data?.data);
        parentRefetch(data?.data?.id, data?.data);
      }
      setClickedRow(data?.data);
      setDispositions(
        data?.data?.contact_dispositions?.map((i: any) => ({
          label: i.name,
          value: i.id,
        }))
      );
    },
  });

  const { data: userObj } = useQuery({
    queryKey: ["userById"],
    queryFn: () => fetchUserById(user?.id),
  });

  const { isLoading: isLoadingEmail, data: userEmailData } = useQuery({
    queryKey: ["fetchUserEmail", id],
    queryFn: () => fetchDealershipEmail(dealer?.id),
    refetchOnWindowFocus: false,
    enabled: id !== undefined,
    retry: false,
  });
  const { data: isOpenPhone } = useQuery({
    queryKey: ["openPhone", user?.dealership?.id],
    queryFn: disableOpenPhone,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: pushToCrmPermission } = useQuery({
    queryKey: ["pushToCrmPermission", dealer?.id, id],
    queryFn: () =>
      checkPushToCrmPermission(
        dealer?.id,
        contactId && contactId !== "undefined" ? contactId : id
      ),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (shouldRefetchDetail) {
      refetch();
    }
    dispatch({
      type: "SET_SHOULD_REFETCH_DETAIL",
      payload: { shouldRefetchDetail: false },
    });
  }, [dispatch, refetch, shouldRefetchDetail]);

  useEffect(() => {
    setDisableOnErrorRes(clickedRow?.dnc);
  }, [clickedRow?.dnc]);

  const { data: emailData } = userEmailData || {};
  const { email: userEmail } = emailData?.[0] || {};
  const isEmailEnabled =
    !!userEmail &&
    userObj?.permissions?.some(
      (permission: any) =>
        permission?.module_key === "emails" && permission?.allowed
    );

  const mutation = useMutation(updateContactDisposition);
  const mutationPushToCrm = useMutation(pushToCrm);

  const handleDNCMutation = () => {
    mutationDNC.mutate({
      contact: {
        dnc: !disableOnErrorRes,
      },
    });
    setIsOpenDNCAlert(false);
    setDisableOnErrorRes((prev: boolean) => !prev);
  };

  const handleSarahMutation = () => {
    mutationSarah.mutate({
      contact: {
        sarah_enabled: !clickedRow?.sarah_enabled,
      },
    });
    setIsOpenSarahAlert(false);
  };

  const handleBlockMutation = () => {
    mutationBlock.mutate({
      contact: {
        block: !clickedRow?.block,
      },
    });
    setIsOpenBlockAlert(false);
  };

  const handlePushToCrm = () => {
    if (!pushToCrmPermission?.data?.permit) {
      return;
    }

    mutationPushToCrm.mutate(
      {
        dId: dealer?.id,
        id,
      },
      {
        onSuccess: () => {
          toast({
            description: "Your request has been put in the queue.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error while pushing contact to CRM: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
    setIsOpenPushToCrm(false);
  };

  const mutationDNC = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateDNC(
          { dId: dealer?.id, id: clickedRow?.id },
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "DNC updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    },
    onError: (error: any) => {
      refetch();
      toast({
        description: `Error updating DNC: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const mutationBlock = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateBlock(
          { dId: dealer?.id, id: clickedRow?.id },
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "Block updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    },
    onError: (error: any) => {
      refetch();
      toast({
        description: `Error updating Block: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const mutationSarah = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateSarah(
          { dId: dealer?.id, id: clickedRow?.id },
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "Sarah updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    },
    onError: (error: any) => {
      refetch();
      toast({
        description: `Error updating Sarah: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const getDisableValue = () => {
    if (impSSRole === "SimpSocial Owner") {
      return false;
    }
    return (
      disableOnErrorRes &&
      (dealershipRoleChecker(role) || impSSRole === "Customer Service Agent")
    );
  };

  const navigateToMessageThread = async () => {
    const { data } = await getHistory({
      dealershipId: dealer?.id,
      contactId: clickedRow?.id,
      type: "Message",
    });

    if (data?.histories?.length) {
      navigate("/leads/lead-details?active_index=0", {
        state: {
          contact_id: clickedRow?.id,
          activeKey: 2,
          activeIndex: 0,
          messageThreadItem: data?.histories[0],
        },
      });
    } else {
      setIsSmsCenterOpen(true);
    }
  };

  const iconButtons = [
    {
      id: 2,
      tooltip:
        disableOnErrorRes || clickedRow?.block
          ? "Messaging not allowed (DNC/Blocked)."
          : !isOpenPhone?.data?.within_hours
          ? "Outside business hours, outbound messages are not permitted."
          : devices.length
          ? "Message"
          : "No Line Exists!",
      icon: <ChatIcon />,
      onclick: () => navigateToMessageThread(),
      isDisabled:
        disableOnErrorRes ||
        clickedRow?.block ||
        !(devices.length && isOpenPhone?.data?.within_hours) ||
        !clickedRow?.phone_number,
    },
    {
      id: 4,
      tooltip: clickedRow?.block
        ? "Outbound calls not allowed (Blocked)."
        : !isOpenPhone?.data?.within_hours
        ? "Outside business hours, outbound calls are not permitted."
        : micPermission === "denied"
        ? "Please enable the microphone in browser settings."
        : devices.length
        ? "Outbound Call"
        : "No Line Exists!",
      icon: (
        <Image
          src={outgoingCall}
          alt="Incoming Call"
          width="1.5rem" // Match the size of other icons
          height="1.5rem" // Match the size of other icons
          color={"#424242"}
        />
      ),
      isDisabled:
        clickedRow?.block ||
        micPermission === "denied" ||
        !(devices.length && isOpenPhone?.data?.within_hours) ||
        !clickedRow?.phone_number,
      active: isOutBoundCall,
      onclick: () => setIsOutBoundCall(true),
    },
    {
      id: 1,
      tooltip:
        disableOnErrorRes || clickedRow?.block
          ? "Emailing not allowed (DNC/Blocked)."
          : isEmailEnabled
          ? "Email"
          : "No Registered Email",
      icon: (
        <EmailIcon
          style={{
            width: "1.75rem",
            height: "1.75rem",
            color: "var(--grey-800)",
          }}
        />
      ),
      active: isEmailCenterOpen,
      onclick: () => setIsEmailCenterOpen(true),
      isDisabled: !isEmailEnabled || !clickedRow?.email || clickedRow?.block,
    },

    {
      id: 3,
      tooltip:
        disableOnErrorRes || clickedRow?.block
          ? "Inbound calls not allowed (DNC/Blocked)."
          : "Inbound Call",
      icon: (
        <Image
          src={incommingCall}
          alt="Incoming Call"
          width="1.5rem" // Match the size of other icons
          height="1.5rem" // Match the size of other icons
          color={"#424242"}
        />
      ),
      active: isInBoundCall,
      onclick: () => setIsInBoundCall(true),
      isDisabled: disableOnErrorRes || clickedRow?.block,
    },
    {
      id: 6,
      tooltip: "Appointment",
      icon: <MeetingCalendarIcon />,
      active: isAppointmentOpen,
      onclick: () => {
        setIsAppointmentOpen(true);
      },
    },
    {
      id: 5,
      tooltip: "Block",
      icon: <BlockUserIcon />,
      active: clickedRow?.block,
      onclick: () => {
        setIsOpenBlockAlert(true);
      },
    },
    {
      id: 7,
      tooltip: "DNC",
      icon: <DNCIcon />,
      active: disableOnErrorRes,
      onclick: () => {
        setIsOpenDNCAlert(true);
      },
      isDisabled: getDisableValue(),
    },
    {
      id: 8,
      tooltip: "Sarah AI",
      icon: <SarahAIIcon />,
      active: !clickedRow?.sarah_enabled,
      onclick: () => {
        setIsOpenSarahAlert(true);
      },
    },
    {
      id: 9,
      tooltip: "Push To CRM",
      icon: <InventoryMappingIcon />,
      onclick: () => {
        setIsOpenPushToCrm(true);
      },
      isDisabled: !pushToCrmPermission?.data?.permit,
    },
  ];

  const handleDispositionChange = (value: any) => {
    mutation.mutate(
      {
        dId: dealer?.id,
        id,
        payload: { contact_dispositions_ids: value },
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            description: "Contact updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          // parentRefetch()
        },
        onError: (error: any) => {
          toast({
            description: `Error updating contact: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  if (id) {
    return (
      <VStack
        alignItems="flex-start"
        padding="1.25rem"
        background="var(--grey-50)"
        w="32%"
        gap="1rem"
        borderRight="1px solid var(--grey-300)"
        overflow="auto"
        height="calc(100vh - 100px)"
      >
        <HStack justifyContent="space-between" w="100%">
          {/* <Image
            src={
              clickedRow?.id
                ? `https://source.unsplash.com/random/1920x1080/?abstract,pattern&${clickedRow?.id}`
                : Avatar
            }
            width="48px"
            height="48px"
            alt="avatar"
            style={{
              border: "2px solid var(--secondary-600)",
              borderRadius: "50%",
            }}
          /> */}
          <AccountCircleIcon
            style={{
              width: "48px",
              height: "48px",
            }}
          />

          <Button
            variant="none"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="0.37rem"
            onClick={() => setIsCPDrawerOpen(true)}
          >
            <SettingsIcon />
          </Button>
        </HStack>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          gap="0.75rem"
        >
          <VStack gap="0.38rem" alignItems="flex-start">
            <Text textStyle="h3" fontWeight="600" color="var(--grey-800)">
              {clickedRow?.use_nick_name && `${clickedRow?.nick_name}`}
              {!clickedRow?.use_nick_name &&
                `${clickedRow?.first_name || ""} ${
                  clickedRow?.last_name || ""
                }`}
            </Text>
            <Text textStyle="h5" fontWeight="400" color="var(--grey-800)">
              {clickedRow?.phone_number || ""}
            </Text>
            <Text textStyle="h5" fontWeight="400" color="var(--grey-800)">
              {clickedRow?.email || ""}
            </Text>
          </VStack>
        </HStack>
        <HStack flexWrap="wrap">
          {iconButtons?.slice(0, 5)?.map((item) => (
            <Tooltip
              key={item.id}
              hasArrow
              label={item.tooltip}
              borderRadius="0.5rem"
              placement="top"
              background="var(--grey-900)"
              padding="0.5rem"
              fontSize="0.75rem"
              fontWeight="600"
              color="white"
            >
              <Button
                variant="none"
                display="flex"
                isDisabled={item.isDisabled}
                sx={{
                  svg: {
                    width:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    height:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    color: item.active ? "white" : "var(--grey-900)",
                    path: {
                      fill: item.active ? "white" : "grey.800",
                    },
                  },
                  background: item.active
                    ? "var(--primary-600)"
                    : "transparent",
                  _hover: {
                    background: "var(--primary-600)",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                alignItems="center"
                justifyContent="center"
                padding="0.5rem"
                background="white"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                onClick={item.onclick}
              >
                {item.icon}
              </Button>
            </Tooltip>
          ))}
        </HStack>
        <HStack flexWrap="wrap">
          {iconButtons?.slice(5, 9)?.map((item) => (
            <Tooltip
              key={item.id}
              hasArrow
              label={item.tooltip}
              borderRadius="0.5rem"
              placement="top"
              background="var(--grey-900)"
              padding="0.5rem"
              fontSize="0.75rem"
              fontWeight="600"
              color="white"
            >
              <Button
                isDisabled={item.isDisabled}
                variant="none"
                display="flex"
                sx={{
                  svg: {
                    width:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    height:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    color: item.active ? "white" : "var(--grey-900)",
                    path: {
                      fill: item.active ? "white" : "grey.800",
                    },
                  },
                  background: item.active
                    ? item.tooltip === "Appointment"
                      ? "var(--primary-600)"
                      : "red"
                    : "transparent",
                  _hover: {
                    background:
                      item.tooltip === "Appointment"
                        ? "var(--primary-600)"
                        : "red",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                alignItems="center"
                justifyContent="center"
                padding="0.5rem"
                background="white"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                onClick={item.onclick}
              >
                {item.icon}
              </Button>
            </Tooltip>
          ))}
        </HStack>
        <HStack
          w="100%"
          gap="0.62rem"
          alignItems="flex-start"
          padding="1rem"
          background="var(--secondary-50)"
          borderRadius="0.5rem"
        >
          <BroadcastIcon color="var(--secondary-600)" />
          <VStack gap="0.5rem" mt="-0.15rem" alignItems="flex-start">
            <Text fontSize="1rem" fontWeight="600">
              Campaign
            </Text>
            <Button
              variant="none"
              color="var(--orange-800)"
              padding="0.5rem 1rem"
              border="1px solid var(--orange-800)"
              borderRadius="0.5rem"
              onClick={onOpen}
            >
              Enroll/Disenroll From Campaigns
            </Button>
          </VStack>
        </HStack>
        <HStack
          w="100%"
          gap="0.62rem"
          alignItems="flex-start"
          padding="1rem"
          background="var(--secondary-50)"
          borderRadius="0.5rem"
        >
          <TagsIcon color="var(--secondary-600)" />
          <VStack gap="0.5rem" mt="-0.15rem" alignItems="flex-start">
            <Text fontSize="1rem" fontWeight="600">
              Tags
            </Text>
            <Button
              variant="none"
              color="var(--orange-800)"
              padding="0.5rem 1rem"
              border="1px solid var(--orange-800)"
              borderRadius="0.5rem"
              onClick={onOpenTags}
            >
              Assign/Unassign Tags
            </Button>
          </VStack>
        </HStack>
        <HStack w="100%" gap="0.62rem" alignItems="flex-start">
          <VStack alignItems="flex-start" w="100%">
            <Text fontSize="1rem" fontWeight="600">
              Contact Disposition Status
            </Text>
            <DebouncedAsyncSelect
              loadOptions={(searchStr) =>
                loadDispositionSettings(searchStr, dealer?.id)
              }
              defaultOptions
              value={dispositions}
              onChange={(value: any) => {
                handleDispositionChange(value?.map?.((i: any) => i.value));
              }}
              isMulti={true}
              placeholder="Select Disposition Status"
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
                menu: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
                container: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </VStack>
        </HStack>

        <VStack alignItems="flex-start" gap="1rem">
          <HStack alignItems="flex-start">
            <VStack alignSelf="stretch" gap="0.3rem">
              <Box
                as="span"
                padding="0.45rem"
                border="1px solid var(--grey-300)"
                background="white"
                borderRadius="0.5rem"
              >
                <LastActivityIcon />
              </Box>
              <VStack height="100%" gap="0">
                <Box
                  as="span"
                  padding="0.25rem"
                  background="var(--grey-300)"
                  borderRadius="50%"
                ></Box>
                <Box
                  as="span"
                  w="1px"
                  height="100%"
                  background="var(--grey-300)"
                ></Box>
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" gap="0.56rem" pt="0.63rem">
              <Text textStyle="h4" fontWeight="600">
                Last Activity
              </Text>
              <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                {convertDateContacts(
                  clickedRow?.last_activity?.created_at,
                  "ddd MMM D, YYYY h:mma"
                )}
              </Text>
              <Text textStyle="h6" fontWeight="500">
                {getLastActivity(clickedRow?.last_activity)}
              </Text>
            </VStack>
          </HStack>
          <HStack alignItems="flex-start">
            <VStack alignSelf="stretch" gap="0.3rem">
              <Box
                as="span"
                padding="0.45rem"
                border="1px solid var(--grey-300)"
                background="white"
                borderRadius="0.5rem"
              >
                <PointPersonIcon />
              </Box>
              <VStack height="100%" gap="0">
                <Box
                  as="span"
                  padding="0.25rem"
                  background="var(--grey-300)"
                  borderRadius="50%"
                ></Box>
                <Box
                  as="span"
                  w="1px"
                  height="100%"
                  background="var(--grey-300)"
                ></Box>
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" gap="0.56rem" pt="0.63rem">
              <VStack alignItems="flex-start" gap="0.25rem">
                <Text textStyle="h4" fontWeight="600">
                  Point Person
                </Text>
              </VStack>
              {clickedRow?.assign_person?.sales_person?.name && (
                <>
                  <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                    Sales Person
                  </Text>
                  <Text textStyle="h6" fontWeight="500">
                    {clickedRow?.assign_person?.sales_person?.name}
                  </Text>
                </>
              )}

              {clickedRow?.assign_person?.manager?.name && (
                <>
                  <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                    Manager
                  </Text>
                  <Text textStyle="h6" fontWeight="500">
                    {clickedRow?.assign_person?.manager?.name}
                  </Text>
                </>
              )}

              {clickedRow?.assign_person?.bdc_agent?.name && (
                <>
                  <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                    BDC Agent
                  </Text>
                  <Text textStyle="h6" fontWeight="500">
                    {clickedRow?.assign_person?.bdc_agent?.name}
                  </Text>
                </>
              )}
            </VStack>
          </HStack>
          <HStack alignItems="flex-start">
            <VStack alignSelf="stretch" gap="0.3rem">
              <Box
                as="span"
                padding="0.45rem"
                border="1px solid var(--grey-300)"
                background="white"
                borderRadius="0.5rem"
              >
                <LastActiveSaleIcon />
              </Box>
              <VStack height="100%" gap="0">
                <Box
                  as="span"
                  padding="0.25rem"
                  background="var(--grey-300)"
                  borderRadius="50%"
                ></Box>
                <Box
                  as="span"
                  w="1px"
                  height="100%"
                  background="var(--grey-300)"
                ></Box>
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" gap="0.56rem" pt="0.63rem">
              <VStack alignItems="flex-start" gap="0.25rem">
                <Text textStyle="h4" fontWeight="600">
                  Last Active Sales Lead
                </Text>
                <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                  {convertDateContacts(
                    clickedRow?.latest_active_lead?.created_at,
                    "ddd MMM D, YYYY h:mma"
                  )}
                </Text>
              </VStack>
              {clickedRow?.latest_active_lead?.vehicle && (
                <Text textStyle="h6" fontWeight="500">
                  {clickedRow?.latest_active_lead?.vehicle?.year}{" "}
                  {clickedRow?.latest_active_lead?.vehicle?.make}{" "}
                  {clickedRow?.latest_active_lead?.vehicle?.model}
                </Text>
              )}
              <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                {clickedRow?.latest_active_lead?.lead_source}
              </Text>
            </VStack>
          </HStack>
        </VStack>
        {isEmailCenterOpen && (
          <EmailDrawer
            isOpen={isEmailCenterOpen}
            onClose={() => setIsEmailCenterOpen(false)}
            clickedRow={clickedRow}
            userEmail={userEmail}
          />
        )}
        {(openSMS || isSmsCenterOpen) && (
          <SmsDrawer
            isOpen={isSmsCenterOpen}
            onClose={() => setIsSmsCenterOpen(false)}
            clickedRow={clickedRow}
          />
        )}
        {/* Variable Drawer */}
        <InsertVariableDrawer
          isOpen={isVariableOpen}
          onClose={() => setIsVariableOpen(false)}
        />
        {/* Inbound Call Drawer */}
        {isInBoundCall && (
          <InboundCall
            isExpandChat={isExpandChat}
            setIsExpandChat={setIsExpandChat}
            setIsInBoundCall={setIsInBoundCall}
            setIsAppointmentOpen={setIsAppointmentOpen}
            clickedRow={clickedRow}
            refetchParent={refetch}
          />
        )}
        {isOutBoundCall && (
          <OutboundCall
            isOpen={isOutBoundCall}
            onClose={() => setIsOutBoundCall(false)}
            clickedRow={clickedRow}
          />
        )}
        {isOpen && (
          <EnrolledCampaignDrawer
            isOpen={isOpen}
            onClose={onClose}
            clickedRow={clickedRow}
            dealership_id={dealer?.id}
          />
        )}
        {isOpenTags && (
          <AssignUnassignTags
            isOpen={isOpenTags}
            onClose={onCloseTags}
            clickedRow={clickedRow}
            dealership_id={dealer?.id}
          />
        )}
        {isCPDrawerOpen && (
          <ContactSettings
            isCPDrawerOpen={isCPDrawerOpen}
            onCPClose={() => {
              setIsCPDrawerOpen(false);
              refetch();
              // parentRefetch();
            }}
            id={clickedRow?.id}
            refetchParent={(id: any, data: any) => {
              refetch();
              parentRefetch(id, data);
            }}
          />
        )}
        {isOpenDNCAlert && (
          <Popup
            isOpen={true}
            onClose={onCloseDNCAlert}
            onConfirm={handleDNCMutation}
            title="DNC Confirmation"
            message="Are you sure you want to update DNC?"
          />
        )}
        {isOpenBlockAlert && (
          <Popup
            isOpen={true}
            onClose={onCloseBlockAlert}
            onConfirm={handleBlockMutation}
            title="Block Confirmation"
            message={
              !clickedRow?.block
                ? "Are you sure you want to block this contact?"
                : "Are you sure you want to unblock this contact?"
            }
          />
        )}
        {isOpenSarahAlert && (
          <Popup
            isOpen={true}
            onClose={onCloseSarahAlert}
            onConfirm={handleSarahMutation}
            title="Sarah AI Confirmation"
            message={
              !clickedRow?.sarah_enabled
                ? "Are you sure you want to enable Sarah AI?"
                : "Are you sure you want to disable Sarah AI?"
            }
          />
        )}
        {isOpenPushToCrm && (
          <Popup
            isOpen={true}
            onClose={onClosePushToCrm}
            onConfirm={handlePushToCrm}
            title="Push To CRM Confirmation"
            message="Are you sure you want to send all data of this contact to the external CRM?"
          />
        )}
        {(isAppointmentOpen || openAppointment) && (
          <CreateAppointment
            isOpen={isAppointmentOpen}
            onClose={() => setIsAppointmentOpen(false)}
            contactid={clickedRow?.id}
            apiCall={() => {
              refetch();
              dispatch({
                type: "SET_SHOULD_REFETCH",
                payload: { shouldRefetch: true },
              });
            }}
            slotData={{ type: { value: "appointment" } }}
          />
        )}

        {(mutationBlock?.isLoading ||
          mutationDNC?.isLoading ||
          mutation.isLoading ||
          isLoadingEmail) && <Spinner />}
      </VStack>
    );
  } else {
    return (
      <VStack w="100%" height="700px" justifyContent="center">
        {!isLoadingContact && !isFetching && (
          <>
            <EmptyIcon />
            <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
              Looks like there's no data at the moment.
            </Text>
          </>
        )}
      </VStack>
    );
  }
};

export default ContactDetails;
